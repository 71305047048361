<template>
    <v-container fill-height class="d-flex justify-center flex-column w-100">
        <v-card style="width: 350px">
            <v-card-title>
                <v-icon large color="primary" class="mr-2">announcement</v-icon>
                Institutional login failed
            </v-card-title>
            <v-card-text>
                <p class="mb-5">Unfortunately your institution didn't provide enough information for us to log you in.</p>
                <p v-if="previousRoute">No worries, you can easily create a separate account for Nuvolos!</p>
                <p v-if="previousRoute" class="caption text-center">In the next step use your academic e-mail address to set up your account.</p>
                <div class="d-flex justify-center w-100">
                    <v-btn @click="goToPrevious" color="primary">
                        <span v-if="previousRoute">Create a Nuvolos account</span>
                        <span v-else>Try a different login method</span>
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            previousRoute: sessionStorage.getItem('nvBeforeSATOSALogin')
        }
    },
    methods: {
        goToPrevious() {
            sessionStorage.setItem('nvSATOSALoginFailed', 'failed')
            document.location = this.previousRoute || this.$router.resolve('login').href
        }
    }
}
</script>
